.comp-1k-wdg-message-container {
  width: 100%;
  display: flex;

  .comp-1k-wdg-message-bubble {
    background: #00000099;
    width: fit-content;
    margin: 8px;
    padding: 4px;
    padding-right: 25px;
    padding-left: 25px;
    border-radius: 30px;
    margin-left: 0;
  }
  .comp-1k-wdg-message-bubble-own {
    margin-left: auto;
  }

  .comp-1k-wdg-message {
    align-self: flex-start;
    color: white;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 300;
    font-size: 12px;
  }

  .comp-1k-wdg-message-author {
    color: #e8701c;
    width: 100%;
    height: 16px;
    font-weight: 500;
    font-size: 12px;
  }
}
