.chat-empty-header {
  height: 55%;
  max-height: 55%;
}
.chat-window-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  height: 45%;
  max-height: 45%;
  padding: 10px;
  box-sizing: border-box;
  position: relative;

  .chat-messages-container {
    max-width: 75%;
    width: 75%;
    margin-left: 5%;
    height: calc(100% - 50px);
    overflow-y: auto;
    position: relative
  }

  .chat-messages-container::-webkit-scrollbar {
    width: 0px;
  }

  .buttons-container {
    width: 20%;
    max-width: 20%;
    position: absolute;
    right: 0;
    bottom: 100px;
  }

  .actions-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    align-self: flex-end;
  }

  .side-button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .side-button {
    width: 20px;
    height: 20px;
    padding: 10px;
    background: #00000099;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .message-input {
    border: 1px solid white;
    border-radius: 30px;
    background: #00000099;
    color: white;
    padding: 8px;
    min-width: 200px;
    width: 100%;
  }
  .message-input::placeholder {
    color: white;
    font-style: italic;
  }

  .name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .new-message-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .input-container {
    width: 70%;
    max-width: 70%;
    margin-left: 5%;
  }

  .submit-button-container {
    width: 20%;
    max-width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
  }

  .submit-button {
    border: 0;
    border-radius: 50%;
    background: #00000099;
    margin-right: 15px;
    margin-left: 10px;
    padding: 10px;
  }

  .submit-button img {
    height: 20px;
    width: 20px;
  }
}

.modal-message {
  font-size: 12px;
  color: #ffffff;
}
