.comp-1k-wdg-trending-carousel-container {
  display: flex;
  overflow: hidden;
  width: 956px;

  .comp-1k-wdg-carousel-content {
    position: relative;
    overflow: hidden;
    display: flex;
    scroll-behavior: smooth;
    align-items: center;
  }

  .comp-1k-wdg-slide {
    transition: width 0.25s ease, height 0.25s ease, opacity 0.25s ease;
    opacity: 0.5;
    flex: 0 0 auto;
    // height: 546px;
    height: 520px;
    width: 295px;
    margin: 6px;
  }

  .comp-1k-wdg-activeSlide {
    transition: width 0.25s ease, height 0.25s ease, opacity 0.25s ease;
    opacity: 1;
    // height: 605px;
    height: 580px;
    width: 328px;
  }

  .comp-1k-wdg-carousel-controls {
    width: 100%;
    max-width: 956px;
    height: 600px;
    overflow: hidden;
    position: absolute;
  }

  .comp-1k-wdg-left-arrow,
  .comp-1k-wdg-right-arrow {
    border: none;
    background: none;
    position: absolute;
    font-size: 24px;
    cursor: pointer;
    user-select: none;
    z-index: 1000;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding-bottom: 5px;
    top: 290px;
    z-index: 100;

  }

  .comp-1k-wdg-left-arrow {
    left: 18px;
  }

  .comp-1k-wdg-right-arrow {
    right: 18px;
  }

  @media screen and (max-width: 1060px) {

    .comp-1k-wdg-carousel-container,
    .comp-1k-wdg-carousel-controls {
      // width: 600px;
      width: 100%;
    }
  }

  @media screen and (max-width: 776px) {
    .comp-1k-wdg-carousel-container {
      scale: calc(0.8)
    }
  }

  @media screen and (max-width: 738px) {
    .comp-1k-wdg-carousel-container {
      position: relative;
      left: -100px;
    }
  }

  @media screen and (max-width: 630px) {
    .comp-1k-wdg-carousel-container {
      margin: 20px 0 50px;
      padding-bottom: 20px;
      position: relative;
      left: 0;
      right: 0;
      scale: calc(1)
    }

    .comp-1k-wdg-carousel-controls {
      width: 100%;
    }

    .comp-1k-wdg-left-arrow,
    .comp-1k-wdg-right-arrow {
      width: 30px;
      height: 30px;
      font-size: 16px;
      padding-bottom: 3px;
    }

    .comp-1k-wdg-left-arrow {
      left: 10px;
    }

    .comp-1k-wdg-right-arrow {
      right: 10px;
    }
  }
}