@media screen and (min-width: 630px) {
  .trending-1k-wgt-container {
    height: 700px;
    background-color: #E8701C;
    display: flex;
    flex-direction: row;
    align-items: center;

    .trending-1k-wgt-intro {
      width: 310px;
      margin-left: 48px;
      margin-right: 85px;
    }

    .trending-1k-wgt-header {

      font-size: 15px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 1px;
      text-align: left;
      color: #FFFFFF;
    }

    .trending-1k-wgt-title {

      font-size: 35px;
      font-weight: 700;
      line-height: 40px;
      text-align: left;
      color: #FFFFFF;
      margin-top: 8px;
      margin-bottom: 25px;
    }

    .trending-1k-wgt-description {

      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #FFFFFF;
    }

    .trending-1k-wgt-description p {

      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: #FFFFFF;
      line-break: auto;
    }

    .trending-1k-wgt-action {
      margin-top: 32px;
      width: 187px;
      height: 57.64px;
      top: 1920.17px;
      left: 44px;
      gap: 0px;
      border-radius: 7px;
      opacity: 0px;
      background-color: #000000;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .trending-1k-wgt-content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media screen and (max-width: 1440px) {
      .trending-1k-wgt-intro {
        margin-right: 0;
      }

      .trending-1k-wgt-content {
        scale: calc(0.8);
        width: 80%;
      }
    }

    @media screen and (max-width: 1220px) {
      .trending-1k-wgt-content {
        scale: calc(0.75);
        width: 75%;
      }
    }

    @media screen and (max-width: 1120px) {
      .trending-1k-wgt-content {
        scale: calc(0.65);
        width: 65%;
      }
    }
  }
}

@media screen and (max-width: 629px) {
  .trending-1k-wgt-container {
    background-color: #E8701C;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .trending-1k-wgt-intro {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }

    .trending-1k-wgt-header {
      font-size: 15px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 1px;
      text-align: center;
      color: #ffffff;

    }

    .trending-1k-wgt-title {
      font-size: 26px;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
      color: #FFFFFF;
      margin-top: 8px;
      margin-bottom: 25px;
      text-align: center;
    }

    .trending-1k-wgt-description {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      margin: 0 20px;
    }

    .trending-1k-wgt-description p {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
    }

    .trending-1k-wgt-action {
      margin-top: 32px;
      width: 187px;
      height: 57.64px;
      top: 1920.17px;
      left: 44px;
      gap: 0px;
      border-radius: 7px;
      opacity: 0px;
      background-color: #000000;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .trending-1k-wgt-content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


@media screen and (max-width: 600px) {
  .trending-1k-wgt-container {

    .trending-1k-wgt-action {
      margin-top: 12px;
    }

    .trending-1k-wgt-intro {
      margin-bottom: 0px;
    }
  }
}