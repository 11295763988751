.comp-1k-wdg-marquee {
  white-space: nowrap;
  overflow: hidden;
  margin-left: -140px;
  background-color: #ffffff;
  height: 64px;
  padding-top: 28px;
  /* border: 1px solid red; */


  .comp-1k-wdg-upcoming {
    font-size: 1.5rem;
    font-weight: 600;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }

  .comp-1k-wdg-showcases {
    color: #E8701C;
    font-size: 1.5rem;
    font-weight: 600;
  }
}