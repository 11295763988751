.stream-1k-wgt-stream-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10001;
  display: flex;
  justify-content: center;
  align-items: center;

  .stream-1k-wgt-stream-container {
    position: relative;
    height: 100%;
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (hover: none) {
    .stream-1k-wgt-stream-container {
      width: 10%;
    }
  }

  .stream-1k-wgt-video-container {
    position: relative;
    height: 100%;
    max-width: 500px;
    min-width: 330px;
    background: #00000099;

    .stream-1k-wgt-close-button {
      position: absolute;
      top: 20px;
      right: 20px;
      border: 1px solid transparent;
      border-radius: 50%;
      background-color: #AAAAAA;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      rotate: 45deg;
      font-size: 30px;
      color: white;
      cursor: pointer;
      z-index: 1001;
    }

    .stream-1k-wgt-live-indicator {
      position: absolute;
      top: 10px;
      left: 20px;
      background-color: #E8701C;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 57px;
      height: 23px;
      border-radius: 100px;
      z-index: 100;
    }

    .stream-1k-wgt-standby-indicator {
      position: absolute;
      top: 10px;
      left: 20px;
      background-color: lightblue;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 23px;
      border-radius: 100px;
      z-index: 100;
    }

    .stream-1k-wgt-collection-info {
      position: absolute;
      top: 36px;
      left: 20px;
      background-color: rgba(51, 51, 51, 0.3);
      color: white;
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: 300px;
      max-height: 50px;
      border-radius: 55px;
      overflow: hidden;
      z-index: 100;

      .stream-1k-wgt-collection-image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;

        img {
          height: 100%;
          width: 100%;
          resize: cover;
        }
      }

      .stream-1k-wgt-collection-name {
        max-width: 250px;
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
      }
    }

    .stream-1k-wgt-product-container {
      width: 110px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      overflow: hidden;
      top: 33%;
      right: 20px;
      background-color: rgba(245, 245, 245, 0.8);
      z-index: 10001;
      cursor: pointer;

      .stream-1k-wgt-product-description {
        display: flex;
        flex-direction: column;
        align-items: center;

        .stream-1k-wgt-product-image-container {
          margin: 0;
          height: 110px;
          width: 110px;
          background-color: white;
          text-align: center;

          img {
            max-height: 110px;
            max-width: 110px;
          }
        }

        .stream-1k-wgt-product-details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          flex-direction: row;
          text-align: center;

          .stream-1k-wgt-product-title {
            font-size: 11px;
            font-weight: 500;
          }

          .stream-1k-wgt-product-price {
            font-size: 11px;
            font-weight: 600;
          }

          .stream-1k-wgt-product-old-price {
            text-decoration: line-through;
          }
        }
      }
    }
  }

  .stream-1k-wgt-chat-container {
    position: absolute;
    height: 100%;
    max-width: 500px;
    z-index: 200;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}


@media screen and (max-width: 776px) {
  .stream-1k-wgt-video-container {
    video {
      object-fit: cover;
    }
  }
}
