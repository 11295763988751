.comp-1k-wdg-modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
  }
  
  .comp-1k-wdg-modal-content {
	background: #00000099;
	padding: 20px;
	border-radius: 8px;
	position: relative;
	width: fit-content;
	max-width: 500px;
  }
  
  .comp-1k-wdg-modal-close {
	position: absolute;
	top: 5px;
	right: 5px;
	background: none;
	border: none;
	font-size: 24px;
	cursor: pointer;
	color: #FFFFFF;
  }