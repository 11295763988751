.comp-1k-wdg-actions-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.comp-1k-wdg-action-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.comp-1k-wdg-action-button {
  width: 24px;
  height: 24px;
  padding: 4px;
  background: #00000066;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.comp-1k-wdg-poster-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}