.comp-1k-wdg-upcoming-carousel-container {
  display: flex;
  overflow: scroll;

  .comp-1k-wdg-upcoming-carousel-content {
    position: relative;
    display: flex;
    scroll-behavior: smooth;
    align-items: center;
    padding: 50px 40px;
  }

  .comp-1k-wdg-slide {
    transition: width 0.25s ease, height 0.25s ease;
    flex: 0 0 auto;
    height: 588px;
    width: 322px;
    margin: 0 24px;
  }

  .comp-1k-wdg-slide:first-child {
    margin-left: 0;
  }

  .comp-1k-wdg-slide:last-child {
    margin-right: 0;
  }

  .comp-1k-wdg-activeSlide {
    transition: width 0.25s ease, height 0.25s ease;
    height: 588px;
    width: 322px;
  }

  .comp-1k-wdg-upcoming-carousel-controls {
    /* width: 100%;
    height: 600px;
    overflow: hidden;
    position: absolute; */
    visibility: hidden;
  }

  .comp-1k-wdg-left-arrow,
  .comp-1k-wdg-right-arrow {
    border: none;
    background: none;
    position: absolute;
    font-size: 24px;
    cursor: pointer;
    user-select: none;
    z-index: 1000;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding-bottom: 5px;
    top: 290px
  }

  .comp-1k-wdg-left-arrow {
    left: 18px;
  }

  .comp-1k-wdg-right-arrow {
    right: 18px;
  }

}


@media screen and (max-width: 776px) {
  .comp-1k-wdg-upcoming-carousel-container {
    width: 780px;
    overflow: hidden;
    scale: calc(0.8);


    .comp-1k-wdg-upcoming-carousel-content {
      overflow: hidden;
    }

    .comp-1k-wdg-upcoming-carousel-controls {
      width: 100%;
      height: 600px;
      overflow: hidden;
      position: absolute;
      visibility: visible !important;

    }
  }
}

@media screen and (max-width: 738px) {
  .comp-1k-wdg-upcoming-carousel-container {
    position: relative;
    left: -40px;
  }
}

@media screen and (max-width: 700px) {
  .comp-1k-wdg-upcoming-carousel-container {
    position: relative;
    left: -60px;
  }
}

@media screen and (max-width: 660px) {
  .comp-1k-wdg-upcoming-carousel-container {
    position: relative;
    left: -70px;
  }
}

@media screen and (max-width: 630px) {
  .comp-1k-wdg-upcoming-carousel-container {
    position: relative;
    left: 0;
    right: 0;
    scale: calc(1);
    width: 100%;

    .comp-1k-wdg-upcoming-carousel-controls {
      width: 100%;
    }

    .comp-1k-wdg-left-arrow,
    .comp-1k-wdg-right-arrow {
      width: 30px;
      height: 30px;
      font-size: 16px;
      padding-bottom: 3px;
    }

    .comp-1k-wdg-left-arrow {
      left: 10px;
    }

    .comp-1k-wdg-right-arrow {
      right: 10px;
    }
  }
}